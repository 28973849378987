<div class="flex h-5 items-center">
  <input
    type="checkbox"
    [id]="id"
    [checked]="value"
    (change)="updateValue()"
    [disabled]="disabled"
    class="focus:ring-primary text-primary h-4 w-4 rounded border-gray-300"
  />
</div>
<div class="ml-3 text-sm">
  <div class="flex">
    <label [for]="id" class="select-none font-medium text-gray-700"
      ><ng-content></ng-content>
    </label>
    @if (tooltip) {
      <mspot-tooltip-info-icon
        [tooltip]="tooltip"
        class="text-primary"
      ></mspot-tooltip-info-icon>
    }
  </div>
  @if (subtitle) {
    <p class="text-gray-500">
      {{ subtitle }}
    </p>
  }
</div>
