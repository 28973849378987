import { Pipe, PipeTransform } from '@angular/core';
import { FormControl, UntypedFormGroup } from '@angular/forms';

@Pipe({
  name: 'formValid',
  pure: false,
})
export class FormValidPipe implements PipeTransform {
  transform(form: FormControl | UntypedFormGroup | undefined | null): boolean {
    return !form?.dirty || form?.invalid || form?.pending;
  }
}
