/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/no-output-native */
/* eslint-disable @angular-eslint/directive-class-suffix */
import { ListRange } from '@angular/cdk/collections';
import {
  DOWN_ARROW,
  ENTER,
  ESCAPE,
  TAB,
  UP_ARROW,
} from '@angular/cdk/keycodes';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ControlValueAccessor, FormControl } from '@angular/forms';
import { SelectDataSource } from '@memberspot/shared/model/ui-components';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject, tap } from 'rxjs';
import {
  buffer,
  debounceTime,
  map,
  startWith,
  switchMap,
} from 'rxjs/operators';

import { SelectDataSourceService } from './data-source/select-data-source.service';
import { OptionComponent } from './option/option.component';
const ITEM_THRESHOLD_TO_SHOW_SEARCH = 6;

@UntilDestroy()
@Directive()
export class SelectBaseComponent
  implements ControlValueAccessor, OnInit, OnChanges, OnDestroy
{
  private _dataSourceService = inject(SelectDataSourceService<unknown>);
  @ViewChild('searchInput', { static: false })
  set searchInput(val: ElementRef<HTMLInputElement>) {
    if (val) {
      val.nativeElement.focus();
    }
  }

  @Input() disabled = false;
  @Input() loading = false;
  @Input() value: any;

  @Input() label?: string;
  @Input() labelTooltip?: string;

  @Input() valueKey = 'id';
  @Input() labelKey = 'name';
  @Input() descriptionKey = 'description';
  @Input() translate = false;

  @Input() items: any[] | null | undefined = [];
  @Input() itemSize = 40;
  @Input() description = false;
  @Input() enableNull = false;
  @Input() multi = false;
  @Input() searchable?: boolean;
  @Input() disableFn?: (item: any) => boolean;

  @Input() tooltips = false;
  @Input() emptyMes?: string;
  @Input() placeholder?: string;
  @Input() noBorder?: boolean;
  @Input() dataSource: SelectDataSource<unknown> = this._dataSourceService;
  @Input() useGhostButtonStyle = false;
  @Input() iconName = 'heroChevronUpDown';

  customStyles = input<string>('');

  @Output() selectionChange = new EventEmitter<any>();

  @ViewChild(CdkVirtualScrollViewport, { static: false })
  scrollPort?: CdkVirtualScrollViewport;

  @ViewChild('selectTrigger', { read: ElementRef })
  selectTrigger?: ElementRef;

  @ViewChildren(OptionComponent) opts?: QueryList<OptionComponent>;

  open = false;
  touched = false;

  width = 210;
  height?: number;

  viewIndex?: number;
  viewValue?: string;
  selectedItem?: any;
  focusedValue?: any;

  searchCtrl = new FormControl('');
  searchItems$ = this.searchCtrl.valueChanges.pipe(
    tap(() => this.dataSource.setLoading(true)),
    debounceTime(300),
    startWith(''),
    switchMap((val) => this.dataSource.getSearchedItems$(val, this.labelKey)),
  );

  get isSearchActive(): boolean {
    return (
      this.searchable ??
      (this.items?.length ?? 0) > ITEM_THRESHOLD_TO_SHOW_SEARCH
    );
  }

  search = new Subject<string>();

  constructor(
    private _elRef: ElementRef,
    private _cdr: ChangeDetectorRef,
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/no-empty-function
  onChange = (val: any) => {};
  // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/no-empty-function
  onTouched = () => {};

  ngOnDestroy(): void {
    this.search.complete();
  }

  ngOnInit(): void {
    this.search
      .pipe(
        untilDestroyed(this),
        buffer(this.search.pipe(debounceTime(500))),
        map(
          (search) =>
            this.items?.findIndex(
              (item) =>
                (item?.[this.labelKey] as string)
                  ?.toLocaleLowerCase()
                  .startsWith(search.join('').toLocaleLowerCase()),
            ),
        ),
      )
      .subscribe((res) =>
        res !== undefined && res >= 0 ? this._goToIndexAndFocus(res) : null,
      );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['description']) {
      this.itemSize = this.description ? 60 : 40;
    }

    if (changes['items'] || changes['value']) {
      this.manualItemsChange();
    }
  }

  manualItemsChange() {
    this._updateViewValue();
    this._updateMultiValueWithMatchingItems(this.value);
    this._cdr.markForCheck();
    this._dataSourceService.data = this.items || [];
    // Needed to disable this otherwise endless loading animation
    // this._setSearchControlAsDisabledIfApplicable();
  }

  selected(value: any) {
    if (this.multi) {
      if (!Array.isArray(this.value)) {
        this.value = [];
      }

      const val = [...this.value];

      if (val.includes(value)) {
        const index = val.findIndex((v) => v === value);

        val.splice(index, 1);
      } else {
        val.push(value);
      }

      this.value = [...val];
      this.focusedValue = value;
    } else {
      this.value = value;
    }

    this._updateViewValue();
    this.onChange(this.value);
    this.selectionChange.emit(this.value);
    this.onTouched();

    if (!this.multi) {
      this.open = false;
      this.focusedValue = undefined;
      this.selectTrigger?.nativeElement.focus();
    }
  }

  triggerMenuOpen() {
    if (!this.open && !this.items?.length) {
      return;
    }

    if (!this.open) {
      this.width = (
        this._elRef.nativeElement as HTMLElement
      ).getBoundingClientRect().width;
    }

    this._focus();
    this.open = !this.open;

    if (this.open) {
      // wait for viewchild to be ready
      if (!this.multi && this.viewIndex !== undefined) {
        setTimeout(() => {
          const index = this.viewIndex as number;

          this.scrollPort?.scrollToIndex(index > 0 ? index - 1 : 0);
        }, 10);
      }

      this.focusedValue = undefined;
    } else {
      this.selectTrigger?.nativeElement.focus();
    }
  }

  writeValue(obj: any): void {
    if (this.multi && this.items) {
      this._updateMultiValueWithMatchingItems(obj);
    } else {
      this.value = obj;
    }

    this._updateViewValue();
    this._cdr.markForCheck();
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    // Needed to disable this otherwise endless loading animation
    // this._setSearchControlAsDisabledIfApplicable();
  }

  onKeydown(event: KeyboardEvent, search = true) {
    switch (event.keyCode) {
      case TAB:
        this.tabHandler(event);

        break;

      case ENTER:
        event.preventDefault();
        this.enterHandler();

        break;

      case ESCAPE:
        event.preventDefault();
        this.escHandler();

        break;

      case UP_ARROW:
        event.preventDefault();
        this.arrowUpHandler();

        break;

      case DOWN_ARROW:
        event.preventDefault();
        this._arrowDownHandler();

        break;

      default:
        if (search) {
          this.search.next(event.key);
        }

        break;
    }
  }

  createTrackByFn() {
    return (index: number, item: any) => item?.[this.valueKey] || index;
  }

  private enterHandler() {
    if (this.focusedValue !== undefined && this.open) {
      this.selected(this.focusedValue);
    } else {
      this.triggerMenuOpen();
    }
  }

  private tabHandler(event: KeyboardEvent) {
    if (!this.open) {
      return;
    }

    event.preventDefault();

    if (this.focusedValue !== undefined && this.open) {
      this.selected(this.focusedValue);
    } else {
      this.triggerMenuOpen();
    }
  }

  private escHandler() {
    if (this.open) {
      this.triggerMenuOpen();
    }
  }

  private arrowUpHandler() {
    let indexToSet = -1;

    if (this.focusedValue === undefined) {
      const index = this.items ? this.items.length : 0;

      this._goToIndexAndFocus(index);

      return;
    }

    const optsLength = this.opts ? this.opts.length : 0;

    for (let index = 0; index < optsLength; index++) {
      const opt = this.opts?.get(index);

      if (opt?.value === this.focusedValue) {
        indexToSet = index > 0 ? index - 1 : index;
        this._focusOption(index);

        break;
      }
    }

    for (let index = 0; index < optsLength; index++) {
      if (index === indexToSet) {
        this._focusOption(index);
        const scrollPos = this.scrollPort?.getRenderedRange();

        this.scrollPort?.scrollToIndex(
          index + this._getScrollPosStart(scrollPos),
        );

        break;
      }

      if (index === optsLength - 1) {
        this._focusOption(0);
      }
    }
  }

  private _arrowDownHandler() {
    if (this.focusedValue === undefined) {
      this._focusFirstOption();

      return;
    }

    if (!this.opts) {
      return;
    }

    let indexToSet: number;

    for (let index = 0; index < this.opts?.length; index++) {
      const opt = this.opts?.get(index);

      if (opt?.value === this.focusedValue) {
        indexToSet = index + 1;

        if (indexToSet === this.opts.length) {
          this._focusOption(this.opts.length - 1);
        } else {
          this._focusOption(index + 1);
          const scrollPos = this.scrollPort?.getRenderedRange();

          this.scrollPort?.scrollToIndex(
            index + this._getScrollPosStart(scrollPos),
          );
        }

        break;
      }
    }
  }

  private _focus() {
    this.selectTrigger?.nativeElement?.focus();
  }

  private _goToIndexAndFocus(index: number) {
    this.scrollPort?.scrollToIndex(index + (this.enableNull ? 1 : 0));
    setTimeout(() => {
      this._findOptionAndFocus(index);
    }, 10);
  }

  private _findOptionAndFocus(itemIndex: number) {
    if (this.items === null || this.items === undefined) {
      return;
    }

    const item = this.items[itemIndex];
    const opt = this.opts?.find((o) => o.value === item[this.valueKey]);

    opt?.focus();
    this.focusedValue = opt?.value;
  }

  private _focusFirstOption() {
    this.scrollPort?.scrollToIndex(0);
    setTimeout(() => {
      this._focusOption(0);
    }, 10);
  }

  private _focusOption(index: number) {
    const opt = this.opts?.get(index);

    opt?.focus();
    this.focusedValue = opt?.value;
  }

  private _updateViewValue() {
    if (this.multi) {
      this.viewValue = Array.isArray(this.value)
        ? this.value
            .map((val) => this._findItemByValue(val)?.[this.labelKey] || '')
            .join(', ')
        : '';
    } else {
      const item = this.items?.find((val) => val[this.valueKey] === this.value);

      if (item) {
        const indexOfItem = this.items?.indexOf(item);

        this.viewIndex = indexOfItem;
      }

      this.selectedItem = item;
      const viewValue = item?.[this.labelKey] || '';

      if (viewValue !== this.viewValue) {
        this.viewValue = viewValue;
        /*this.searchCtrl.patchValue(this.viewValue as string);*/
      }
    }

    const length = (this.items?.length || 0) + (this.enableNull ? 1 : 0);

    this.height = length > 6 ? 240 : length * this.itemSize + 10;
  }

  private _findItemByValue(val: any) {
    return this.items?.find((i) => i?.[this.valueKey] === val);
  }

  private _updateMultiValueWithMatchingItems(newValue: any[]) {
    if (this.multi && this.items) {
      if (Array.isArray(newValue)) {
        this.value = newValue.filter(
          (o) =>
            (this.items as any[]).findIndex((i) => i?.[this.valueKey] === o) >=
            0,
        );
      } else {
        this.value = [];
      }
    }
  }

  private _getScrollPosStart(scrollPos: ListRange | undefined) {
    return scrollPos ? scrollPos.start : 0;
  }

  private _setSearchControlAsDisabledIfApplicable() {
    if (this.isSearchActive && (this.disabled || this.items?.length === 0)) {
      this.searchCtrl.disable();
    }

    if (
      this.isSearchActive &&
      !this.disabled &&
      (this.items?.length || 0) > 0
    ) {
      this.searchCtrl.enable();
    }
  }
}
