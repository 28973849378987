/* eslint-disable @angular-eslint/no-host-metadata-property */
import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  HostBinding,
  Input,
} from '@angular/core';

import { INPUT_PREFIX } from '../form-field-std/form-field-std.component';

@Component({
  selector: 'mspot-input-prefix',
  templateUrl: './input-prefix.component.html',
  styles: [],
  host: {
    class:
      'block absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none',
  },
  providers: [
    {
      provide: INPUT_PREFIX,
      useExisting: forwardRef(() => InputPrefixComponent),
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputPrefixComponent {
  @Input() icon: any;

  @HostBinding('class')
  @Input()
  colorClass = 'icon-color';
}
