import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TooltipPosition } from '@angular/material/tooltip';

@Component({
  selector: 'mspot-tooltip-info-icon-sm',
  templateUrl: './tooltip-info-icon-sm.component.html',
  styles: [],
  host: {
    class: 'inline-flex',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TooltipInfoIconSmComponent {
  @Input() tooltip = '';
  @Input() position: TooltipPosition = 'below';
}
