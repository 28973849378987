@if (label) {
  <label mspotFormLabel [for]="id">
    {{ label }}
  </label>
}

<div>
  <mspot-form-field-base
    [invalid]="control?.touched && control?.invalid"
    [errors]="control?.errors"
  >
    <ng-content></ng-content>
    <span suffix>
      <ng-content select="[mspotInputSuffix]"></ng-content>
    </span>
  </mspot-form-field-base>
</div>
