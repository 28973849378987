<mspot-form-field-std class="mb-4 w-full sm:mb-0 sm:w-1/2">
  <mspot-input-prefix icon="hero-magnifying-glass"></mspot-input-prefix>
  <input
    mspotInput
    [formControl]="searchControl"
    [placeholder]="sarchPlaceholder"
    autocomplete="off"
    type="text"
  />
</mspot-form-field-std>

<div class="flex flex-col items-stretch gap-3 sm:flex-row sm:items-center">
  <ng-content select="[slot='beforeAdd']"></ng-content>

  @if (add.observers.length && addEnabled) {
    <button
      data-cy="addBtn"
      class="btn--icon btn-primary whitespace-nowrap sm:w-auto"
      (click)="add.emit()"
      [disabled]="addDisabledState"
    >
      {{ addText }}
    </button>
  }
  @if (addLink) {
    <a
      data-cy="addLink"
      class="btn--icon btn-primary w-full whitespace-nowrap sm:w-auto"
      [routerLink]="addLink"
    >
      {{ addText }}
    </a>
  }
</div>
