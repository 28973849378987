import { Injectable } from '@angular/core';
import { SelectDataSource } from '@memberspot/shared/model/ui-components';
import { Observable, of, ReplaySubject, tap } from 'rxjs';

@Injectable()
export class SelectDataSourceService<T> implements SelectDataSource<T> {
  isLoading$ = new ReplaySubject<boolean>(1);

  data: T[] = [];

  setLoading(isLoading: boolean): void {
    this.isLoading$.next(isLoading);
  }

  getSearchedItems$(
    searchText: string | null,
    labelKey: string,
  ): Observable<T[]> {
    if (!searchText) {
      return of(this.data).pipe(tap(() => this.isLoading$.next(false)));
    }

    return of(
      this.data.filter(
        (item: any) =>
          item?.[labelKey].toLowerCase().includes(searchText.toLowerCase()),
      ),
    ).pipe(tap(() => this.isLoading$.next(false)));
  }
}
