/* eslint-disable @angular-eslint/no-host-metadata-property */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  InjectionToken,
  Input,
  Renderer2,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';

import { InputErrorDirective } from '../input-error/input-error.directive';
import { InputNoMinDirective } from '../input-error/input-no-min.directive';

export const INPUT_PREFIX = new InjectionToken('input prefix');
export const INPUT_SUFFIX = new InjectionToken('input suffix');

@UntilDestroy()
@Component({
  selector: 'mspot-form-field-std:not([tooltip])',
  templateUrl: './form-field-std.component.html',
  styles: [],
  host: {
    class: 'block',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormFieldStdComponent {
  id?: string;

  @Input() label?: string;

  control?: NgControl;

  private _inputErrorDir?: InputErrorDirective;
  @ContentChild(InputErrorDirective) set inputError(val: InputErrorDirective) {
    if (val) {
      if (val.host.id) {
        this.id = val.host.id;
      } else {
        const id = Math.random().toString(16);

        this.id = id;
        val.host.id = id;
      }

      if (this._isNoMinInput(val)) {
        this._renderer.setStyle(
          this._elRef.nativeElement,
          'width',
          `${val.width}px`,
        );
      }

      this.control = val.ngControl;
      val.registerCdr(this._cdr);
      this._inputErrorDir = val;
    }
  }

  @ContentChild(INPUT_PREFIX) set inputPrefix(val: any) {
    if (val && this._inputErrorDir) {
      this._inputErrorDir.host.classList.add('pl-10');
    }
  }

  @ContentChild(INPUT_SUFFIX) set inputSuffix(val: any) {
    if (val && this._inputErrorDir) {
      this._inputErrorDir.host.classList.add('pr-10');
    }
  }

  constructor(
    private _cdr: ChangeDetectorRef,
    private _renderer: Renderer2,
    private _elRef: ElementRef,
  ) {}

  triggerChangeDetection() {
    this._cdr.markForCheck();
  }

  private _isNoMinInput(
    dir: InputErrorDirective | InputNoMinDirective,
  ): dir is InputNoMinDirective {
    return 'width' in dir;
  }
}

@Component({
  selector: 'mspot-form-field-std[tooltip]',
  templateUrl: './form-field-std-tooltip.component.html',
})
export class FormFieldStdTooltipComponent extends FormFieldStdComponent {
  @Input() tooltip?: string;
}
