/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @angular-eslint/no-host-metadata-property */
import { FocusableOption } from '@angular/cdk/a11y';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
} from '@angular/core';

@Component({
  selector: 'li[mspotOption]',
  templateUrl: './option.component.html',
  host: {
    class:
      // eslint-disable-next-line max-len
      'group cursor-default select-none relative py-2 pl-3 pr-9 text-gray-700 hover:text-text-gray-900 hover:bg-gray-100 rounded-md mx-1 border-2 focus-visible:rounded-lg border-transparent focus-visible:border-primary',
    'attr.role': 'option',
    tabindex: '-1',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptionComponent implements FocusableOption {
  @Input()
  selected = false;

  @Input() showSelectedIcon = true;

  @Input() value: any;

  disabled?: boolean;

  constructor(private _elRef: ElementRef) {}

  getLabel?(): string {
    throw new Error('Method not implemented.');
  }

  focus() {
    this._elRef.nativeElement.focus();
  }
}
