import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { debounceTime } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'mspot-search-add',
  templateUrl: './search-add.component.html',
  styles: [],
  host: {
    class: 'flex flex-col justify-between p-4 sm:flex-row',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchAddComponent {
  @Input() sarchPlaceholder = 'search';
  @Input() addText = 'Add';
  @Input() addLink?: string;
  @Input() addEnabled: boolean | null = true;
  @Input() addDisabledState: boolean | null = false;

  @Input() set addDisabled(val: boolean | null) {
    this.addEnabled = !val;
  }

  searchControl = new UntypedFormControl();

  @Output() add = new EventEmitter();
  @Output() search = this.searchControl.valueChanges.pipe(debounceTime(200));
}
