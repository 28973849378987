@if (label) {
  <label
    id="listbox-label"
    class="mb-1 flex items-center truncate text-sm font-medium text-gray-700"
  >
    <span>
      {{ label }}
    </span>
    @if (labelTooltip) {
      <mspot-tooltip-info-icon-sm
        [tooltip]="labelTooltip"
      ></mspot-tooltip-info-icon-sm>
    }
  </label>
}
<div cdkOverlayOrigin #trigger="cdkOverlayOrigin">
  <button
    type="button"
    [ngClass]="buttonStyles"
    aria-haspopup="listbox"
    aria-expanded="true"
    aria-labelledby="listbox-label"
    (click)="triggerMenuOpen()"
    style="height: 38px"
    (keydown)="onKeydown($event)"
    #selectTrigger
    tabindex="0"
    [disabled]="disabled || !items?.length"
    data-cy="selectComponentDropDownBtn"
  >
    <div #buttonIcon class="-ml-0.5 flex items-center empty:hidden">
      <ng-content select="[slot='icon']"></ng-content>
    </div>

    <div class="empty:hidden">
      <ng-content select="[slot='buttonPrefix']"></ng-content>
    </div>

    <ng-content select="[slot='selectBtnContent']"></ng-content>

    <ng-container
      *ngTemplateOutlet="
        !viewValue
          ? emptyTpl
          : optionTpl && !multi
            ? optionTpl
            : icon && iconKey
              ? iconTpl
              : titleTpl;
        context: { $implicit: selectedItem }
      "
    >
    </ng-container>

    <span
      class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
    >
      @if (!loading) {
        @if (!customStyles()) {
          <ng-icon [name]="iconName" class="text-xl text-gray-400" />
        }
      } @else {
        <svg
          class="h-5 w-5 animate-spin text-gray-400"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            class="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"
          ></circle>
          <path
            class="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      }
    </span>
  </button>
</div>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="open"
  [cdkConnectedOverlayOffsetY]="1"
  (backdropClick)="open = false"
  [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayWidth]="width"
>
  <div
    class="bg-dwhite flex w-full flex-col rounded-md text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
  >
    @if (isSearchActive) {
      <mspot-form-field-std class="p-1.5">
        <input
          #searchInput
          mspotInput
          [formControl]="searchCtrl"
          [placeholder]="'common.search' | transloco"
          type="text"
          class="peer/input h-10 min-w-fit rounded-md border-none bg-gray-100 pl-9 pr-8 shadow-none focus:ring-0"
          (keydown)="onKeydown($event, false)"
        />

        @if (dataSource.isLoading$ | async) {
          <div class="absolute right-1 top-[50%] translate-y-[-50%] scale-75">
            <ng-container *ngTemplateOutlet="loadingTpl"></ng-container>
          </div>
        }

        <span
          class="peer-focus/input:text-primary pointer-events-none absolute inset-y-0 left-0 flex items-center justify-center px-2 text-gray-400 transition-colors peer-hover/input:text-gray-500"
        >
          <ng-icon
            name="heroMagnifyingGlass"
            class="text-lg text-inherit"
          ></ng-icon>
        </span>
      </mspot-form-field-std>
    }

    <cdk-virtual-scroll-viewport
      class="scrollbar mb-1 w-full overflow-auto pb-1"
      [style.height.px]="height || 0 + 8"
      tabindex="-1"
      role="listbox"
      aria-labelledby="listbox-label"
      (keydown)="onKeydown($event)"
      [@openClose]
      [itemSize]="40"
    >
      @if (!multi) {
        @if (enableNull) {
          <li
            mspotOption
            id="listbox-option-null"
            (click)="selected(null)"
            [value]="null"
            [showSelectedIcon]="showSelectedIcon"
            class="list-none"
          >
            -
          </li>
        }

        <li
          *cdkVirtualFor="
            let item of isSearchActive ? (searchItems$ | async) : items;
            index as i;
            trackBy: createTrackByFn()
          "
          mspotOption
          #option
          [id]="'listbox-option-' + i"
          (click)="selected(item[valueKey])"
          [selected]="item[valueKey] === value"
          [value]="item[valueKey]"
          [matTooltip]="item[labelKey]"
          [matTooltipDisabled]="!tooltips"
          [matTooltipShowDelay]="500"
          [showSelectedIcon]="showSelectedIcon"
          matTooltipPosition="left"
          class="list-none"
        >
          <ng-container
            *ngTemplateOutlet="
              optionTpl
                ? optionTpl
                : icon && iconKey
                  ? iconTpl
                  : description
                    ? descTpl
                    : stdTpl;
              context: { $implicit: item }
            "
          ></ng-container>
        </li>
      } @else {
        @if (enableNull) {
          <li
            mspotMultiOption
            id="listbox-option-null"
            (click)="selected(null)"
            [value]="null"
          >
            -
          </li>
        }
        @for (item of items; track createTrackByFn()(i, item); let i = $index) {
          <li
            mspotMultiOption
            [id]="'listbox-option-' + i"
            (click)="selected(item[valueKey])"
            [value]="item[valueKey]"
            [selectedValue]="value"
            [matTooltip]="item[labelKey]"
            [matTooltipDisabled]="!tooltips"
            [matTooltipShowDelay]="500"
          >
            <ng-container
              *ngTemplateOutlet="
                optionTpl
                  ? optionTpl
                  : icon && iconKey
                    ? iconTpl
                    : description
                      ? descTpl
                      : stdTpl;
                context: { $implicit: item }
              "
            ></ng-container>
          </li>
        }
      }
    </cdk-virtual-scroll-viewport>
  </div>
</ng-template>

<ng-template #emptyTpl>
  <span class="whitespace-nowrap text-gray-500">
    {{
      !items?.length
        ? emptyMes ||
          placeholder ||
          (customStyles() || useGhostButtonStyle
            ? ''
            : ('common.select' | transloco))
        : placeholder ||
          (customStyles() || useGhostButtonStyle
            ? ''
            : ('common.select' | transloco))
    }}
  </span>
</ng-template>

<ng-template #titleTpl>
  <span class="block truncate">
    {{ translate ? (viewValue | transloco) : viewValue }}
  </span>
</ng-template>

<ng-template #stdTpl let-item>
  <span class="block truncate">
    {{
      translate ? (item && item[labelKey] | transloco) : item && item[labelKey]
    }}
  </span>
</ng-template>

<ng-template #descTpl let-item>
  <div class="h-10">
    <span class="block truncate">
      {{
        translate
          ? (item && item[labelKey] | transloco)
          : item && item[labelKey]
      }}
    </span>
    <span class="block truncate font-normal text-gray-500">
      {{
        translate
          ? item && (item[descriptionKey] | transloco)
          : item && item[descriptionKey]
      }}
    </span>
  </div>
</ng-template>

<ng-template #iconTpl let-item>
  <span class="flex items-center truncate">
    <!-- [name]="item | icon : [iconKey, iconMap]" -->
    <ng-icon [name]="item[iconKey]" class="mr-2 text-xl"></ng-icon>
    {{
      translate ? (item && item[labelKey] | transloco) : item && item[labelKey]
    }}
  </span>
</ng-template>

<ng-template #loadingTpl>
  <svg
    class="h-5 w-5 animate-spin text-gray-400"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle
      class="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      stroke-width="4"
    ></circle>
    <path
      class="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    ></path>
  </svg>
</ng-template>
