/* eslint-disable @angular-eslint/no-host-metadata-property */
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

@Component({
  selector: 'mspot-error',
  templateUrl: './control-error.component.html',
  styles: [],
  host: {
    class: 'block mt-1 text-sm text-red-600',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ControlErrorComponent {
  @Input() errors?: ValidationErrors | null;
}
