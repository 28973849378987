/* eslint-disable @angular-eslint/no-host-metadata-property */
import { animate, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  forwardRef,
  HostBinding,
  Input,
  signal,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { SelectDataSourceService } from '../data-source/select-data-source.service';
import { SelectBaseComponent } from '../select';

@Component({
  selector: 'mspot-select',
  templateUrl: './select.component.html',
  styles: [],
  host: {
    class: 'relative inline-block text-left min-w-0',
  },
  animations: [
    trigger('openClose', [
      transition(':leave', [
        style({
          opacity: 1,
        }),
        animate(
          '100ms ease-in',
          style({
            opacity: 0,
          }),
        ),
      ]),
    ]),
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
    SelectDataSourceService,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectComponent extends SelectBaseComponent {
  @ViewChild('buttonIcon')
  set buttonIcon(ref: ElementRef) {
    if (ref) {
      this.buttonIconRef.set(ref.nativeElement);
    }
  }

  @Input() iconKey = 'icon';
  @Input() icon = false;
  @Input() showSelectedIcon = true;

  @HostBinding('class.w-full')
  @Input()
  fullWidth = true;

  @ContentChild('optionTpl', { read: TemplateRef })
  optionTpl?: TemplateRef<any>;

  buttonIconRef = signal<HTMLElement | null>(null);

  get buttonStyles() {
    let styles = '';

    if (!this.customStyles()) {
      styles += 'w-full py-2 pl-3 pr-10 text-sm ';
    }

    if (this.open) {
      styles += 'ring-primary border-primary outline-none ring-1 ';
    }

    if (!this.noBorder && !this.useGhostButtonStyle) {
      styles += 'border shadow-sm ';
    }

    if ((this.buttonIconRef()?.children?.length || 0) > 0) {
      styles += 'flex items-center gap-2 ';
    }

    if (!this.useGhostButtonStyle && !this.customStyles()) {
      styles +=
        'bg-dwhite focus:ring-primary focus:border-primary relative w-full truncate rounded-md border border-gray-900/20 text-left shadow-sm transition-colors hover:enabled:border-gray-900/40 focus:outline-none focus:ring-1 ';
    }

    if (this.useGhostButtonStyle) {
      styles += 'btn btn-gray--basic flex ';
    }

    if (this.customStyles()) {
      styles += this.customStyles();
    }

    return styles;
  }

  constructor(elRef: ElementRef, cdr: ChangeDetectorRef) {
    super(elRef, cdr);
  }
}
