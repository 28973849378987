import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'mspot-checkbox:not([noLabel])',
  templateUrl: './checkbox.component.html',
  styles: [],
  host: {
    class: 'relative flex items-start',
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxComponent<T = any> implements ControlValueAccessor {
  @Input() value?: boolean = false;
  @Input() subtitle?: string;
  @Input() tooltip?: string;

  @Input() linkedValue?: T;
  @Input() noValueUpdate = false;

  @Output() valueChanged = new EventEmitter();

  id = Math.random().toString();

  @Input() disabled = false;

  @Input() set checked(val: boolean | undefined) {
    this.value = !!val;
    this.cdr.markForCheck();
  }

  get checked() {
    return this.value;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  changed = (val: any) => {};
  touched = () => {};

  constructor(private cdr: ChangeDetectorRef) {}

  updateValue() {
    if (this.noValueUpdate) {
      return;
    }

    this.value = !this.value;
    this.touched();
    this.changed(this.value);
    this.valueChanged.emit(this.value);
  }

  writeValue(obj: any): void {
    this.value = obj;
    this.cdr.markForCheck();
  }

  registerOnChange(fn: any): void {
    this.changed = fn;
  }

  registerOnTouched(fn: any): void {
    this.touched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdr.markForCheck();
  }
}
