/* eslint-disable @angular-eslint/no-host-metadata-property */
import {
  ChangeDetectorRef,
  ComponentRef,
  Directive,
  ElementRef,
  HostBinding,
  Optional,
  Self,
} from '@angular/core';
import { NgControl } from '@angular/forms';

import { ControlErrorComponent } from '../control-error/control-error.component';

@Directive({})
export abstract class InputErrorBaseDirective {
  ref?: ComponentRef<ControlErrorComponent>;
  parentCdr?: ChangeDetectorRef;

  get host(): HTMLElement {
    return this.elRef.nativeElement;
  }

  abstract errorClasses: string;
  abstract disabledClasses: string;
  abstract validClasses: string;

  @HostBinding('class') get invalid() {
    this.parentCdr?.markForCheck();

    return this.ngControl?.invalid && this.ngControl?.touched
      ? this.errorClasses
      : this.ngControl?.disabled
        ? this.disabledClasses
        : this.validClasses;
  }

  constructor(
    private elRef: ElementRef,
    @Optional() @Self() public ngControl?: NgControl,
  ) {}

  registerCdr(cdr: ChangeDetectorRef) {
    this.parentCdr = cdr;
  }
}
