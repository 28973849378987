/* eslint-disable @angular-eslint/no-host-metadata-property */
import { Directive } from '@angular/core';

import { InputErrorBaseDirective } from './input-error-base.directive';

@Directive({
  host: {
    class: `
      block 
      rounded-md 
      text-sm
      bg-dwhite
      shadow-sm
      `,
    autocomplete: 'off',
    type: 'text',
  },
})
export abstract class InputErrorDirective extends InputErrorBaseDirective {
  errorClasses =
    'pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500';

  disabledClasses = 'pr-10';

  validClasses = 'border-gray-300 focus:border-primary focus:ring-primary';
}
