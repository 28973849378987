import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'mspot-tooltip-info-icon',
  templateUrl: './tooltip-info-icon.component.html',
  styles: [],
  host: {
    class: 'inline-flex',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TooltipInfoIconComponent {
  @Input() tooltip = '';
}
