/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @angular-eslint/no-host-metadata-property */
import { FocusableOption } from '@angular/cdk/a11y';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  Input,
} from '@angular/core';

import { OptionComponent } from '../option/option.component';

@Component({
  selector: 'li[mspotMultiOption]',
  templateUrl: './option.component.html',
  host: {
    class:
      // eslint-disable-next-line max-len
      'flex items-center group cursor-default select-none relative py-2 pl-3 pr-9 text-gray-900 hover:text-white hover:bg-primary border-2 focus-visible:rounded-lg border-transparent focus-visible:border-primary',
    'attr.role': 'option',
    tabindex: '-1',
  },
  providers: [
    {
      provide: OptionComponent,
      useExisting: forwardRef(() => OptionMultiComponent),
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptionMultiComponent implements FocusableOption {
  selected = false;

  @Input() value: any;

  private _selectedValue: any[] = [];

  @Input() set selectedValue(val: any[]) {
    this._selectedValue = val;

    if (val && val?.includes(this.value)) {
      this.selected = true;
    } else {
      this.selected = false;
    }
  }

  get selectedValue() {
    return this._selectedValue;
  }

  disabled?: boolean;

  constructor(
    private elRef: ElementRef,
    private cdr: ChangeDetectorRef,
  ) {}

  getLabel?(): string {
    throw new Error('Method not implemented.');
  }

  focus() {
    this.elRef.nativeElement.focus();
  }
}
