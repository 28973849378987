/* eslint-disable @angular-eslint/no-host-metadata-property */
import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: 'label[mspotFormLabel]',
  host: {
    class: 'block mb-1 text-sm text-gray-700 font-medium',
  },
})
export class FormFieldLabelDirective {
  @HostBinding('attr.for')
  @Input()
  for?: string;

  constructor() {}
}
