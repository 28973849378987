import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

@Component({
  selector: 'mspot-form-field-base',
  templateUrl: './form-field.component.html',
  styles: [],
  host: {
    class: 'block relative',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormFieldComponent {
  @Input() invalid?: boolean | null;
  @Input() errors?: ValidationErrors | null;
}
