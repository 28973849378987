<div class="flex items-center">
  @if (label) {
    <label mspotFormLabel [for]="id">
      {{ label }}
    </label>
  }
  @if (tooltip) {
    <mspot-tooltip-info-icon
      [tooltip]="tooltip"
      class="text-primary mb-1"
    ></mspot-tooltip-info-icon>
  }
</div>

<div>
  <mspot-form-field-base
    [invalid]="control?.touched && control?.invalid"
    [errors]="control?.errors"
  >
    <ng-content></ng-content>
    <span suffix>
      <ng-content select="[mspotInputSuffix]"></ng-content>
    </span>
  </mspot-form-field-base>
</div>
