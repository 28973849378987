/* eslint-disable @angular-eslint/no-host-metadata-property */
import { Directive, forwardRef, HostBinding, Input } from '@angular/core';

import { InputErrorDirective } from './input-error.directive';

@Directive({
  selector: '[mspotInput]:not([width])',
  host: {
    class: `
      min-w-input
      `,
    autocomplete: 'off',
  },
  providers: [
    {
      provide: InputErrorDirective,
      useExisting: forwardRef(() => InputErrorMinWidthDirective),
    },
  ],
})
export class InputErrorMinWidthDirective extends InputErrorDirective {
  @Input()
  @HostBinding('class.w-full')
  fullWidth = true;
}
