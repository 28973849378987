import { Pipe, PipeTransform } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';

interface LengthError {
  requiredLength: number;
  actualLength: number;
}
interface MaxError {
  max: number;
  actual: number;
}

type i18ControlError = (err?: any) => {
  key: string;
  params?: Record<string, any>;
};

const controlErrors: Record<string, i18ControlError> = {
  required: () => ({
    key: 'formErrors.required',
  }),
  minlength: ({ requiredLength }: LengthError) => ({
    key: 'formErrors.minLength',
    // key: 'formErrors.minLength',
    params: { requiredLength },
  }),

  maxlength: ({ requiredLength, actualLength }: LengthError) => ({
    key: 'formErrors.maxlength',
    params: { requiredLength, actualLength },
  }),

  pattern: () => ({
    key: 'formErrors.pattern',
  }),

  email: () => ({
    key: 'formErrors.email',
  }),
  emailInvalid: () => ({
    key: 'formErrors.emailInvalid',
  }),
  mailProviderInvalid: () => ({
    key: 'formErrors.mailProviderInvalid',
  }),
  isNotANumber: () => ({
    key: 'formErrors.isNotANumber',
  }),
  isNotPositiveWholeNumber: () => ({
    key: 'formErrors.isNotPositiveWholeNumber',
  }),
  isNotInFuture: () => ({
    key: 'formErrors.isNotInFuture',
  }),
  min: () => ({
    key: 'formErrors.min',
  }),

  max: ({ max }: MaxError) => ({
    key: 'formErrors.max',
    params: { max },
  }),

  urlTaken: () => ({
    key: 'formErrors.urlTaken',
  }),
  invalidVideoProvider: () => ({
    key: 'formErrors.invalidVideoProvider',
  }),
  urlInvalid: () => ({
    key: 'formErrors.urlInvalid',
  }),
  urlInvalidHttp: () => ({
    key: 'formErrors.urlInvalidHttp',
  }),
  mspotHostInvalid: () => ({
    key: 'formErrors.mspotHostInvalid',
  }),
  hostnameInvalid: () => ({
    key: 'formErrors.hostnameInvalid',
  }),
  wrongCName: () => ({
    key: 'formErrors.wrongCName',
  }),
  noPasswordMatch: () => ({
    key: 'formErrors.noPasswordMatch',
  }),
  containsOtherCharacters: () => ({
    key: 'formErrors.containsOtherCharacters',
  }),
  onlyValidPhoneCharacters: () => ({
    key: 'formErrors.onlyValidPhoneCharacters',
  }),
  dateMustBeInFuture: () => ({
    key: 'formErrors.dateMustBeInFuture',
  }),
  timeMustBeAtLeastTenMinutesInFuture: () => ({
    key: 'formErrors.timeMustBeAtLeastTenMinutesInFuture',
  }),
  onlyWhitespace: () => ({
    key: 'formErrors.onlyWhitespace',
  }),
  containsSpace: () => ({
    key: 'formErrors.containsSpace',
  }),
  cannotHaveDuplicatedLinks: () => ({
    key: 'formErrors.cannotHaveDuplicatedLinks',
  }),
  isNotEqualToValue: () => ({
    key: 'formErrors.isNotEqualToValue',
  }),
  emailExists: () => ({
    key: 'formErrors.emailExists',
  }),
  valueNotChanged: () => ({
    key: 'formErrors.valueNotChanged',
  }),
  numberIsNegative: () => ({
    key: 'formErrors.numberIsNegative',
  }),
  wrongPassword: () => ({
    key: 'formErrors.wrongPassword',
  }),
  wrongUser: () => ({
    key: 'formErrors.wrongUser',
  }),
  unknownError: () => ({
    key: 'formErrors.unknownError',
  }),
  noVimeoLink: () => ({
    key: 'formErrors.noVimeoLink',
  }),
  noYoutubeLink: () => ({
    key: 'formErrors.noYoutubeLink',
  }),
  youtubeVimeo: () => ({
    key: 'formErrors.youtubeVimeo',
  }),
  vatInvalid: () => ({
    key: 'formErrors.vatInvalid',
  }),
  invalidHexColorCode: () => ({
    key: 'formErrors.invalidHexColorCode',
  }),
  startsWith: (startsWith) => ({
    key: 'formErrors.startsWith',
    params: { startsWith },
  }),
  hasLinkInText: () => ({
    key: 'formErrors.hasLinkInText',
  }),
  hasVariableInText: () => ({
    key: 'formErrors.hasVariableInText',
  }),
  timestampsOutOfVideoRange: () => ({
    key: 'formErrors.timestampsOutOfVideoRange',
  }),
};

@Pipe({
  name: 'errorObj',
  pure: true,
})
export class FormErrorObjPipe implements PipeTransform {
  constructor(private _translocoService: TranslocoService) {}

  transform(err: ValidationErrors | undefined | null): string | null {
    if (!err) {
      return null;
    }

    const errorKey = Object.keys(err)[0];
    const message =
      controlErrors[errorKey] && controlErrors[errorKey](err[errorKey]);

    const key = message.key;
    const params = message.params;

    // const scope: TranslocoScope = 'formErrors';
    // return this.translocoService.translate(key, params, scope);
    return this._translocoService.translate<string>(key, params);
  }
}
